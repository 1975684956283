html,
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
  font-size: 16px;
}

html,
body,
#root {
  height: 100%;
}

.onramper-pane {
  margin: auto;
}

.widget-container {
  --border-radius: 30px;
  margin: auto;
  max-width: 482px;
  max-height: 660px;
  height: 100%;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
  margin: auto;
}

@media (max-width: 575px), (max-height: 615px) {
  .widget-container {
    --border-radius: 0;
    max-width: 100%;
    max-height: 100%;
  }
}
